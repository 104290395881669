import { dbCollections } from "@/utils/FirebaseCollections";
import { mongodbSnapshot } from "@/utils/MongoQueries/crudOperationsWithSanpshot/crudOperationsWithSanpshot";
import { mongodbCrudOperations } from "@/utils/MongoQueries/crudOperations/crudOperations";
import { BSON } from "realm-web";

export const setChats = ({commit}, payload) => {
    return new Promise((resolve, reject) => {
        try {
            const getQuery = {
                collection: dbCollections.TASKS,
                data: [
                    {
                        mainChat: true,
                        ProjectID: BSON.ObjectID(payload.projectId),
                        AssigneeUserId: payload.userId
                    }
                ],
                type: "find"
            }
            const snapQuery = {
                subCollection: dbCollections.TASKS,
                watchFilter: {
                    filter: {
                        $or: [
                            {
                                operationType: "delete"
                            }, {
                                operationType: {$in: ["insert", "update", "replace"]},
                                "fullDocument.mainChat": true,
                                "fullDocument.ProjectID": BSON.ObjectID(payload.projectId),
                                "fullDocument.AssigneeUserId": payload.userId
                            }
                        ]
                    }
                }
            }
            mongodbSnapshot(snapQuery, ({data, type, snap, error}) => {
                if(error) {
                    console.error("ERROR in main chat snap: ", error);
                    reject(error)
                } else {
                    if(type === "inital") {
                        mongodbCrudOperations(getQuery)
                        .then((res) => {
                            commit('mutateChats', res.map((x) => ({snap: null, op: "added", data: {...x}})));
                            resolve(res);
                        })
                        .catch((error) => {
                            reject(error);
                        })
                    } else {
                        let result = [];
                        if(type === "insert") {
                            const docData = data.fullDocument;
                            result.push({snap, op: "added", data: {...docData}});
                        } else if(type === "update") {
                            const docData = data.fullDocument;
                            result.push({snap, op: "modified", data: {...docData}});
                        } else if(type === "replace") {
                            const docData = data.fullDocument;
                            result.push({snap, op: "modified", data: {...docData}});
                        } else if(type === "delete") {
                            const docData = data.documentKey._id;
                            result.push({snap, op: "removed", data: {...docData}});
                        }

                        commit('mutateChats', result);
                    }
                }
            })
        } catch (e) {
            reject(e);
        }
    })
}

export const setChatProjects = ({commit}) => {
    return new Promise((resolve, reject) => {
        try {
            const snapQuery = {
                subCollection: dbCollections.MAIN_CHATS,
                watchFilter: {}
            }
            const getQuery = {
                type: "find",
                collection: dbCollections.MAIN_CHATS,
                data: []
            }
            mongodbSnapshot(snapQuery, ({error, snap, type, data}) => {
                if(error) {
                    reject(error)
                } else {
                    if(type === "inital") {
                        mongodbCrudOperations(getQuery)
                        .then((chats) => {
                            resolve(chats);
                            chats.forEach((ch) => {
                                commit("mutateChatProjects", [{snap, op: "added", data: {...ch, _id: ch._id}}]);
                            })
                        })
                        .catch((error) => {
                            reject(error)
                        })
                    } else if(type === "insert") {
                        const docData = data.fullDocument;
                        commit("mutateChatProjects", [{snap, op: "added", data: {...docData, _id: docData._id}}]);
                    } else if(type === "update" || type === "replace") {
                        const docData = data.fullDocument;
                        commit("mutateChatProjects", [{snap, op: "modified", data: {...docData, _id: docData._id}}]);
                    } else if(type === "delete") {
                        const docId = data.documentKey._id;
                        commit("mutateChatProjects", [{snap, op: "removed", data: {_id: docId}}]);
                    }
                }
            })
        } catch (error) {
            reject(error);
        }
    })
}