export function mutateChats(state, payload) {
    payload.forEach((pay) => {
        const {snap, op, data} = pay;
        if(snap) {
            state.chats.snap = snap;
        }
        if(op === "added") {
            state.chats.data.push(data);
        } else if(op === "modified") {
            const index = state.chats.data.findIndex((x) => x._id === data._id);
            if(index !== -1) {
                state.chats.data[index] = data;
            }
        } else if(op === "removed") {
            const index = state.chats.data.findIndex((x) => x._id === data._id);
            if(index !== -1) {
                state.chats.data.splice(index, 1);
            }
        }
    })
}

export function mutateChatProjects(state, payload) {
    payload.forEach((pay) => {
        const {snap, op, data} = pay;
        if(snap) {
            state.mainChatProjects.snap = snap;
        }
        if(op === "added") {
            const index = state.mainChatProjects.data.findIndex((rule) => rule._id === data._id);

            if(index !== -1) {
                state.mainChatProjects.data[index] = data;
            } else {
                state.mainChatProjects.data.push(data);
            }
        } else if(op === "modified") {
            const index = state.mainChatProjects.data.findIndex((rule) => rule._id === data._id);
            if(index !== -1) {
                state.mainChatProjects.data[index] = data;
            }
        } else if(op === "removed") {
            const index = state.mainChatProjects.data.findIndex((rule) => rule._id === data._id);
            if(index !== -1) {
                state.mainChatProjects.data.splice(index, 1);
            }
        }
    })
}